const achievmentsConfg = [
  {
    value: "23 года",
    description: "Работы на рынке",
  },
  {
    value: ">900",
    description: "Выполненных заказов",
  },
  {
    value: "1 час",
    description: "Минимальное время рассмотрения заявки",
  },
  {
    value: "12",
    description: "Опытных инженеров и монтажников",
  },
];

export default achievmentsConfg;
