const RedGradient = () => {
    return (
        <div className='red-gradient'>
            <svg width="563" height="1292" viewBox="0 0 563 1292" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="238" y="588" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="278" y="588" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="318" y="588" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="358" y="588" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="398" y="588" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="438" y="588" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="478" y="588" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="518" y="588" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="558" y="588" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="238" y="628" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="278" y="628" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="318" y="628" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="358" y="628" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="398" y="628" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="438" y="628" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="478" y="628" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="518" y="628" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="558" y="628" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="238" y="668" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="278" y="668" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="318" y="668" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="358" y="668" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="398" y="668" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="438" y="668" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="478" y="668" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="518" y="668" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="558" y="668" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="238" y="708" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="278" y="708" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="318" y="708" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="358" y="708" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="398" y="708" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="438" y="708" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="478" y="708" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="518" y="708" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="558" y="708" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="238" y="748" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="278" y="748" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="318" y="748" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="358" y="748" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="398" y="748" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="438" y="748" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="478" y="748" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="518" y="748" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="558" y="748" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="238" y="788" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="278" y="788" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="318" y="788" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="358" y="788" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="398" y="788" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="438" y="788" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="478" y="788" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="518" y="788" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="558" y="788" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="238" y="828" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="278" y="828" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="318" y="828" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="358" y="828" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="398" y="828" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="438" y="828" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="478" y="828" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="518" y="828" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="558" y="828" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="238" y="868" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="278" y="868" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="318" y="868" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="358" y="868" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="398" y="868" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="438" y="868" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="478" y="868" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="518" y="868" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="558" y="868" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="238" y="908" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="278" y="908" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="318" y="908" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="358" y="908" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="398" y="908" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="438" y="908" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="478" y="908" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="518" y="908" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <rect x="558" y="908" width="8" height="8" fill="white" fillOpacity="0.1"/>
                <g opacity="0.3" filter="url(#filter0_f_527_4170)">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M634.229 320.676C733.141 326.037 817.711 383.366 879.471 460.813C941.228 538.258 980.695 633.909 961.503 731.085C941.65 831.612 876.828 925.018 780.756 960.653C692.115 993.531 601.42 941.897 519.262 895.118C446.484 853.681 385.635 798.41 357.355 719.582C323.33 624.738 295.767 515.069 353.222 432.291C413.684 345.18 528.346 314.938 634.229 320.676Z"
                          fill="url(#paint0_linear_527_4170)"/>
                </g>
                <defs>
                    <filter id="filter0_f_527_4170" x="0" y="0" width="1286.39" height="1291.03"
                            filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feGaussianBlur stdDeviation="160" result="effect1_foregroundBlur_527_4170"/>
                    </filter>
                    <linearGradient id="paint0_linear_527_4170" x1="24.7423" y1="654.895" x2="657.002" y2="1282.64"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#E64E5C"/>
                        <stop offset="1" stopColor="#FF7A50"/>
                    </linearGradient>
                </defs>
            </svg>
        </div>
    )
}

export default RedGradient;
