import Sber from "../icons/payments/Sber";
import VTB from "../icons/payments/VTB";
import RZD from "../icons/payments/RZD";

const PaymentsRow = () => {
  return (
    <div id='clients' className="flex flex-col lg:flex-row justify-between opacity-30">
      <div className="payment"><Sber /></div>
      <div className="payment"><RZD /></div>
      <div className="payment"><VTB /></div>
      {/*<div className="payment"><Mir /></div>*/}
      {/*<div className="payment"><Vorkassa /></div>*/}
    </div>
  );
};

export default PaymentsRow;
