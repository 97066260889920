const Button = ({ text }) => {
  const onClick = () => {
    const apiResult = window.jivo_api

    try {
      apiResult && apiResult.open();
    }
    catch (e) {
      if (apiResult.result === 'fail') {
        throw new Error('Widget failed to open');
      }
    }
  }
  return (
    <button onClick={onClick} className="button-gradient rounded text-xl px-4 py-3 whitespace-nowrap cursor-pointer text-center	">
      {text}
    </button>
  );
};

export default Button;
