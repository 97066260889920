const Adress = () => {
  return (
    <div className="lg:order-2 order-1 min-w-[500px]">
      <div className="opacity-50">Адрес</div>
      <div className="mt-7">
        <div className="big-title mb-3">Ярославль</div>
        <div className="text-lg">Ленинградский проспект, д.33, оф.209а</div>
        <a href='https://yandex.ru/maps/-/CCUynJt3GA' target='_blank' rel="noreferrer" className="text-primary-orange text-lg underline">Посмотреть на карте</a>
      </div>
      <div className="py-10">
        <div className="opacity-50 mb-4">Номер телефона</div>
        <a href="tel:+74852580336" className="big-title block">+7 4852 58-03-36</a>
        <a href="tel:+74852580337" className="big-title block">+7 4852 58-03-37</a>
      </div>
      <div>
        <div className="opacity-50 mb-4">Электронная почта</div>
        <a href="mailto:info@byte-pro.ru" className="big-title font-navigation block">info@byte-pro.ru</a>
      </div>
    </div>
  );
};

export default Adress;
