import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { mapIcons } from "../utils/iconMapper";
import Button from "./Button";

const Modal = ({ handleClose, open, modalContent }) => {
  const Icon = mapIcons(modalContent.icon);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      BackdropProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, .7)",
          backdropFilter: "blur(3px)",
        },
      }}
      PaperProps={{
        style: {
          backgroundColor: "rgb(15, 16, 16)",
          border: "1px solid rgba(255, 255, 255, 0.2)",
          color: "#fff",
        },
      }}
    >
      <div className="flex justify-center p-5 text-6xl">
        <Icon
          sx={{
            color: "rgb(65, 242, 210)",
          }}
          fontSize="inherit"
        />
      </div>
      <DialogTitle id="alert-dialog-title">{modalContent.title}</DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          sx={{
            color: "rgba(255, 255, 255, 0.7)",
          }}
        >
          {modalContent.description}
        </DialogContentText>
      </DialogContent>
      <div className="py-5 flex justify-center">
        <Button text={"Заказать услугу"} />
      </div>
    </Dialog>
  );
};

export default Modal;
