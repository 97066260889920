const RZD = () => {
    return (
        <svg height="45px" enableBackground="new 0 0 283.632 126.071" version="1.1" viewBox="0 0 284 126"
             xmlns="http://www.w3.org/2000/svg">
      <title>Russian Railways logo</title>
            <g fill="white">
    <path
        d="m201 11.2v4.59h39.4c2.62 0 6.57 0 9.2 2.62 2.63 2.63 2.63 6.57 2.63 9.2v39.4c0 2.63 0 6.57-2.63 9.19-2.63 2.63-6.58 2.63-9.2 2.63h-19c-3.28 0-7.56 0-9.2-3.61-1.63-3.62 0.654-6.9 2.64-9.52l25.6-34.1h-39.4l-15.8 21c-2.64 3.52-4.92 6.57-4.92 10.5 0 3.94 2.12 6.78 4.92 10.5l3.94 5.25c3.94 5.25 7.88 10.5 13.1 13.1 5.26 2.61 11.5 2.61 20 2.61h17.1c9.86 0 24.6 0 34.5-9.84 9.84-9.85 9.84-24.3 9.84-29.5v-15.8c0-5.26 0-19.7-9.84-29.5-9.86-9.86-24.6-9.83-34.5-9.84l-27.2-0.015c-2.61 0-5.91 0-8.54 2.63-2.61 2.63-2.61 5.91-2.61 8.54"/>
                <polygon points="154 31.5 193 31.5 146 94.5 106 94.5"/>
                <path
                    d="m0 42.7c0-2.62 0-5.91 2.63-8.54 2.62-2.62 5.92-2.62 8.54-2.62h66c8.53 0 14.8 0 20 2.62 5.24 2.64 9.18 7.89 13.1 13.1l3.95 5.25c2.81 3.73 4.94 6.57 4.94 10.5 0 3.94-2.3 6.99-4.94 10.5l-15.8 21h-39.4l25.6-34.1c1.97-2.63 4.28-5.9 2.63-9.52-1.63-3.6-5.9-3.6-9.18-3.6h-30.9v78.8h-31.5v-78.8h-15.8v-4.6"/>
  </g>
        </svg>

    );
};

export default RZD;
