import ServicesConf from "../configs/services";
import ServiceCard from "./ServiceCard";
import { useState } from "react";
import Modal from "./Modal";

const ServicesRow = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    description: "",
  });
  const handleClose = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      setIsModalOpen(false);
    }
  };
  const onServiceCardsClick = (modalContent) => {
    setModalContent(modalContent);
    setIsModalOpen(true);
  };
  const servicesCards = () =>
    ServicesConf.map((service) => (
      <ServiceCard
        key={service.title}
        title={service.title}
        body={service.body}
        price={service.price}
        icon={service.modalContent.icon}
        setModalContent={() => onServiceCardsClick(service.modalContent)}
      />
    ));
  return (
    <div
      id="services"
      className="flex flex-col mt-12 lg:flex-row justify-center"
    >
      {servicesCards()}
      <Modal
        open={isModalOpen}
        handleClose={handleClose}
        modalContent={modalContent}
      />
    </div>
  );
};

export default ServicesRow;
