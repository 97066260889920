import "./Map.css";

const Map = () => {
  return (
    <div className="container w-[95%] lg:w-[100%] lg:order-1 order-2 pt-16 lg:h-auto h-96 lg:max-w-[65%]">
      <iframe
        title="Yandex Map"
        src="https://yandex.ru/map-widget/v1/?z=12&ol=biz&oid=1106107442"
        width="100%"
        height="400"
        frameBorder="0"
      ></iframe>
    </div>
  );
};

export default Map;
