const title =
  "Предоставляем все виды услуг по обслуживанию компьютерной техники";
const body = " Более 20 лет опыта и сотни довольных клиентов";

const Mojo = () => {
  return (
    <div className="flex flex-col items-start mt-16">
      <div className="big-title">{title}</div>
      <div className="py-6 opacity-50">{body}</div>
    </div>
  );
};

export default Mojo;
