const Sber = () => {
  return (
      <svg width="210" height="33" viewBox="0 0 210 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M28.9083 6.35486C29.6749 7.35932 30.3239 8.45058 30.8421 9.60673L16.1419 20.5889L9.99829 16.6859V11.9904L16.1419 15.8934L28.9083 6.35486Z" fill="white"/>
        <path d="M3.72827 16.3545C3.72827 16.1438 3.73334 15.9345 3.74348 15.7264L0.0202772 15.5415C0.00760464 15.8112 4.49933e-06 16.0835 4.49933e-06 16.3583C-0.00157962 18.5055 0.415154 20.6318 1.22631 22.6153C2.03746 24.5989 3.22706 26.4006 4.72687 27.9171L7.36783 25.2419C6.21333 24.0766 5.29746 22.6915 4.67286 21.1663C4.04826 19.6411 3.72725 18.0058 3.72827 16.3545Z" fill="white"/>
        <path d="M16.1385 3.77747C16.3463 3.77747 16.5528 3.78518 16.7581 3.79545L16.9444 0.0208629C16.6775 0.00801981 16.4088 0.00159904 16.1385 0.00159904C14.0188 -0.00102064 11.9196 0.420712 9.96126 1.24258C8.00291 2.06445 6.22405 3.27027 4.72681 4.7908L7.36777 7.4673C8.51766 6.29688 9.8845 5.36835 11.3897 4.73512C12.8949 4.10189 14.5087 3.77643 16.1385 3.77747Z" fill="white"/>
        <path d="M16.1385 28.9318C15.9307 28.9318 15.7241 28.9318 15.5176 28.9151L15.3313 32.6884C15.5991 32.7021 15.8682 32.709 16.1385 32.709C18.2573 32.7112 20.3555 32.2892 22.3128 31.4671C24.2701 30.645 26.0478 29.439 27.5439 27.9185L24.908 25.2433C23.7579 26.4131 22.3912 27.3411 20.8863 27.9741C19.3814 28.6071 17.7679 28.9326 16.1385 28.9318Z" fill="white"/>
        <path d="M23.1339 5.97207L26.2716 3.6282C23.4037 1.27465 19.8238 -0.0065332 16.1335 2.50537e-05V3.77718C18.6324 3.77404 21.0733 4.53938 23.1339 5.97207Z" fill="white"/>
        <path d="M32.2759 16.3545C32.2779 15.3719 32.1931 14.391 32.0224 13.4237L28.5489 16.018C28.5489 16.1298 28.5489 16.2415 28.5489 16.3545C28.5498 18.1118 28.1863 19.8497 27.4821 21.4555C26.7778 23.0613 25.7484 24.4992 24.4607 25.676L26.9661 28.4823C28.6402 26.9506 29.978 25.0795 30.8927 22.9903C31.8075 20.901 32.2787 18.6402 32.2759 16.3545Z" fill="white"/>
        <path d="M16.1386 28.9318C14.4045 28.9324 12.6896 28.5639 11.1049 27.8502C9.52028 27.1365 8.10119 26.0934 6.93956 24.7886L4.17188 27.3264C5.68303 29.0233 7.52923 30.3793 9.59077 31.3066C11.6523 32.2338 13.8831 32.7116 16.1386 32.7089V28.9318Z" fill="white"/>
        <path d="M7.81645 7.0329L5.31235 4.22668C3.63776 5.75806 2.29948 7.62906 1.3843 9.71834C0.469132 11.8076 -0.00249507 14.0685 9.92663e-06 16.3544H3.72828C3.72751 14.5972 4.09104 12.8593 4.79529 11.2535C5.49955 9.64775 6.52883 8.20981 7.81645 7.0329Z" fill="white"/>
        <g clip-path="url(#clip0)">
          <path d="M93.1298 9.35282L97.7473 5.94421H82.2935V26.427H97.7473V23.0184H86.7031V17.7717H96.1243V14.3631H86.7031V9.35282H93.1298Z" fill="white"/>
          <path d="M71.8952 13.9236H66.53V9.35147H75.0927L79.7074 5.9325H62.123V26.4153H71.3277C76.4883 26.4153 79.45 24.0717 79.45 19.9845C79.45 16.0744 76.7674 13.9236 71.8952 13.9236ZM71.1292 23.0056H66.53V17.3301H71.1292C73.9204 17.3301 75.2291 18.2812 75.2291 20.1679C75.2291 22.0545 73.8366 23.0056 71.1292 23.0056Z" fill="white"/>
          <path d="M109.107 5.94128H100.695V26.424H105.104V20.6216H109.107C114.474 20.6216 117.794 17.7919 117.794 13.2705C117.794 8.74923 114.474 5.94128 109.107 5.94128ZM109.014 17.2104H105.104V9.35249H109.014C111.837 9.35249 113.395 10.7487 113.395 13.283C113.395 15.8173 111.837 17.2104 109.014 17.2104Z" fill="white"/>
          <path d="M55.5064 21.5902C54.3674 22.2082 53.0913 22.5282 51.7962 22.5206C47.9198 22.5206 45.1077 19.7477 45.1077 15.9294C45.1077 12.111 47.9198 9.33209 51.7962 9.33209C53.1941 9.3083 54.563 9.73303 55.7029 10.5443L58.7871 8.2684L58.5785 8.11991C56.7794 6.53499 54.3755 5.69556 51.6239 5.69556C48.6394 5.69556 45.9332 6.70166 44.001 8.53205C43.024 9.47449 42.2514 10.6086 41.7311 11.8636C41.2109 13.1186 40.9542 14.4675 40.9773 15.8263C40.9566 17.1989 41.2121 18.5616 41.7285 19.8331C42.2449 21.1046 43.0116 22.2587 43.9829 23.2267C45.9241 25.1298 48.6243 26.1783 51.5876 26.1783C54.6839 26.1783 57.3902 25.0964 59.2226 23.1267L56.4619 21.066L55.5064 21.5902Z" fill="white"/>
          <path d="M135.277 13.9236H129.914V9.35147H141.439V5.9325H125.504V26.4153H134.697C139.857 26.4153 142.819 24.0717 142.819 19.9845C142.831 16.0744 140.149 13.9236 135.277 13.9236ZM134.511 23.0056H129.914V17.3301H134.511C137.302 17.3301 138.61 18.2812 138.61 20.1679C138.61 22.0545 137.23 23.0056 134.511 23.0056Z" fill="white"/>
          <path d="M192.947 26.386H188.477V5.9032H192.947V14.5061H195.38L201.95 5.9032H207.016L199.223 15.6471L208.272 26.386H202.341L195.69 18.3303H192.947V26.386Z" fill="white"/>
          <path d="M159.059 22.4934H149.603L148 26.4709H143.243L152.112 5.98816H156.868L165.593 26.4709H160.662L159.059 22.4934ZM157.578 18.7821L154.359 10.827L151.122 18.7821H157.578Z" fill="white"/>
          <path d="M171.611 5.98816V14.5414H180.861V5.98816H185.47V26.4709H180.861V18.4H171.611V26.4709H167.002V5.98816H171.611Z" fill="white"/>
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="168.414" height="21.6207" fill="white" transform="translate(40.9656 5.6897)"/>
          </clipPath>
        </defs>
      </svg>
  );
};

export default Sber;
