import achievmentsConfg from "../configs/achievments";

const AchivmentsGrid = () => {
  const grid = achievmentsConfg.map((achievment) => (
    <div key={achievment.description}>
      <div className="big-title text-numbers-navy font-bold font-navigation">
        {achievment.value}
      </div>
      <div>{achievment.description}</div>
    </div>
  ));

  return (
    <div className="grid grid-rows-2 grid-flow-col gap-12">
      {grid}
    </div>
  );
};

export default AchivmentsGrid;
