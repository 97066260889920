import LogoIcon from "../icons/LogoIcon";
import Button from "./Button";

const Navigation = () => {
  return (
    <div className="py-8 page-padding flex items-center justify-between flex-nowrap navigation">
      <div className="md:scale-150">
        <LogoIcon />
      </div>
      <div className="hidden lg:flex font-navigation">
        <a className="nav-link" href="#services">Услуги</a>
        <a className="nav-link" href="#clients">Клиенты</a>
        <a className="nav-link" href="#contacts">Контакты</a>
      </div>
        <div className="flex justify-end items-center">
            <a href="tel:+74852580336" className="hidden lg:block md:block mr-8">+7 4852 58-03-36</a>
            <Button text="Связаться с нами" />
        </div>
    </div>
  );
};

export default Navigation;
