const ServicesConf = [
  {
    title: "Диагностика и ремонт компьютеров и ноутбуков",
    price: "8 000",
    modalContent: {
      icon: "computer",
      title: "Диагностика и ремонт компьютеров и ноутбуков",
      description:
        " В мастерской компании «Байт-Про» вы можете заказать диагностику и ремонт компьютеров. Мы в кратчайшие сроки определим проблемы с вашим оборудованием и предложим варианты по приведению его в работоспособное состояние. Так же произведем чистку и профилактику Вашего ноутбука или системного блока, что предотвратит его поломку и перегрев. Так же поможем с заменой или upgrade оборудования. Мы предлагаем качественный сервис, доступные цены и даем гарантию на все технические услуги.",
    },
  },
  {
    title: "Ремонт и обслуживание принтеров, МФУ и оргтехники",
    price: "12 000",
    modalContent: {
      icon: "printer",
      title: "Ремонт и обслуживание принтеров, МФУ и оргтехники",
      description:
        "В мастерской компании «Байт-Про» вы можете диагностику и ремонт Вашего печатающего оборудования и другой оргтехники. Наши специалисты в кратчайшие сроки выявят проблему поломки, выявят износ ресурсных запчастей и предложат Вам оптимальный по цене и срокам ремонт вашей техники. Оргтехника имеет механизмы которые требуют со временем требуют чистки, смазки и обслуживания. Мы предлогам не дожидаясь поломки производить диагностико-техническое обслуживание техники. Это позволит сэкономить в последствии деньги на ремонт и простой оборудования.",
    },
  },
  {
    title: "ИТ – аутсорсинг. Обслуживание ПК и оргтехники",
    price: "15 000",
    modalContent: {
      icon: 'repair',
      title: "ИТ – аутсорсинг. Обслуживание ПК и оргтехники",
      description: `В компания «Байт-Про»  предлагает проведение технического обслуживания ПК. Мы предоставляем полный комплекс услуг, начиная от планового осмотра и заканчивая ремонтом или заменой рабочих узлов, настройкой локальной сети. Мы обеспечиваем:
      Установку и настройку ПО, ПК и IT-оборудования
      Оперативное устранение всех неисправностей
      Обеспечение безопасности
      Восстановление утерянной информации
      Удаленная оперативная помощь.
      Сотрудничество с нашим сервисным центром дает нашим клиентам следующие преимущества:
      Мы берем на себя весь спектр задач. Помимо контроля работоспособности техники отвечаем за настройку сетей, программного обеспечения, оргтехники, помощь в подборе нового оборудования.
      Защита вашей системы и компьютеров от вредоносного ПО;
      Выгодные предложения относительно разового обслуживания;
      Оперативность и удобная форма сотрудничества;`,
    },
  },
  {
    title: "Заправка и восстановление картриджей",
    price: "20 000",
    isHit: true,
    modalContent: {
      icon: 'paint',
      title: "Заправка и восстановление картриджей",
      description: `В мастерской компании «Байт-Про» Мы осуществляем ремонт и заправку картриджей черно-белых лазерных принтеров всех производителей: HP, Canon, Samsung, Xerox, Ricoh, Kyocera, Panasonic, Oki, Brother.
      При поступлении картриджа к нам в сервисный центр, мы тщательно проверим его на исправность. При необходимости заменим неисправные узлы. Если ресурс вашего картриджа полностью исчерпан, мы прямо скажем вам об этом.
      При ремонте и заправке мы используем только оригинальные запчасти и расходные материалы. В наличии на нашем складе имеются запчасти и тонер-краска для 95% картриджей лазерных принтеров.
      `,
    },
  },
  {
    title: "Продажа программного обеспечения",
    price: "15 000",
    modalContent: {
      icon: 'terminal',
      title: "Продажа программного обеспечения",
      description: `В мастерской компании «Байт-Про» Мы осуществляем ремонт и заправку картриджей черно-белых лазерных принтеров всех производителей: HP, Canon, Samsung, Xerox, Ricoh, Kyocera, Panasonic, Oki, Brother.
      При поступлении картриджа к нам в сервисный центр, мы тщательно проверим его на исправность. При необходимости заменим неисправные узлы. Если ресурс вашего картриджа полностью исчерпан, мы прямо скажем вам об этом.
      При ремонте и заправке мы используем только оригинальные запчасти и расходные материалы. В наличии на нашем складе имеются запчасти и тонер-краска для 95% картриджей лазерных принтеров.
      `,
    },
  },
];

export default ServicesConf;
