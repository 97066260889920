import Button from "./Button";
import AchivmentsGrid from "./AchivmentsGrid";
import PaymentsRow from "./PaymemtsRow";

const title = "Индивидуальный подход к каждому клиенту";

const Info = () => {
  return (
    <div className="mb-24 lg:mb-40">
      <div className="flex flex-col lg:flex-row items-center pr-10 py-20 lg:py-36 justify-between">
        <div className="flex flex-col items-center lg:mb-0 mb-20">
          <div className="big-title mb-7">{title}</div>
          {/*<Button text={'Заказать услугу'} />*/}
        </div>
        <AchivmentsGrid id="clients" />
      </div>
      <PaymentsRow />
    </div>
  );
};

export default Info;
