const GreenGradient = () => {
    return (
        <div>
            <svg width="1000" height="831" viewBox="0 0 1000 831" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.3" filter="url(#filter0_f_527_4169)">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M134.179 -10C123.749 5.14868 114.85 21.4348 107.42 37.8289C93.1745 69.2611 93.8528 103.435 94.5388 138.003C94.8821 155.303 95.2274 172.702 93.7052 189.905C92.016 208.995 87.0069 228.387 82.0259 247.67C72.1042 286.08 62.2942 324.058 79.0576 358.355C97.6483 396.391 138.579 413.727 179.15 430.91C193.389 436.941 207.583 442.953 220.752 449.835C231.381 455.389 241.904 461.415 252.432 467.443C290.125 489.026 327.884 510.647 370.823 510.821C411.682 510.986 447.678 491.049 483.818 471.031C496.324 464.104 508.847 457.168 521.596 451.051C537.294 443.519 554.896 437.718 572.529 431.907C615.089 417.881 657.827 403.796 674.395 365.172C688.361 332.616 673.054 299.346 657.357 265.23C646.016 240.579 634.471 215.488 633.62 189.905C632.797 165.167 642.271 140.518 651.594 116.263C664.137 83.6281 676.407 51.7068 662.951 21.244C657.673 9.29433 650.204 -1.01474 641.165 -10H134.179Z"
                          fill="url(#paint0_linear_527_4169)"/>
                </g>
                <circle cx="262" cy="203" r="1" fill="white"/>
                <circle cx="302" cy="203" r="1" fill="white"/>
                <circle cx="342" cy="203" r="1" fill="white"/>
                <circle cx="382" cy="203" r="1" fill="white"/>
                <circle cx="422" cy="203" r="1" fill="white"/>
                <circle cx="462" cy="203" r="1" fill="white"/>
                <circle cx="502" cy="203" r="1" fill="white"/>
                <circle cx="542" cy="203" r="1" fill="white"/>
                <circle cx="582" cy="203" r="1" fill="white"/>
                <circle cx="622" cy="203" r="1" fill="white"/>
                <circle cx="262" cy="243" r="1" fill="white"/>
                <circle cx="302" cy="243" r="1" fill="white"/>
                <circle cx="342" cy="243" r="1" fill="white"/>
                <circle cx="382" cy="243" r="1" fill="white"/>
                <circle cx="422" cy="243" r="1" fill="white"/>
                <circle cx="462" cy="243" r="1" fill="white"/>
                <circle cx="502" cy="243" r="1" fill="white"/>
                <circle cx="542" cy="243" r="1" fill="white"/>
                <circle cx="582" cy="243" r="1" fill="white"/>
                <circle cx="622" cy="243" r="1" fill="white"/>
                <circle cx="262" cy="283" r="1" fill="white"/>
                <circle cx="302" cy="283" r="1" fill="white"/>
                <circle cx="342" cy="283" r="1" fill="white"/>
                <circle cx="382" cy="283" r="1" fill="white"/>
                <circle cx="422" cy="283" r="1" fill="white"/>
                <circle cx="462" cy="283" r="1" fill="white"/>
                <circle cx="502" cy="283" r="1" fill="white"/>
                <circle cx="542" cy="283" r="1" fill="white"/>
                <circle cx="582" cy="283" r="1" fill="white"/>
                <circle cx="622" cy="283" r="1" fill="white"/>
                <circle cx="262" cy="323" r="1" fill="white"/>
                <circle cx="302" cy="323" r="1" fill="white"/>
                <circle cx="342" cy="323" r="1" fill="white"/>
                <circle cx="382" cy="323" r="1" fill="white"/>
                <circle cx="422" cy="323" r="1" fill="white"/>
                <circle cx="462" cy="323" r="1" fill="white"/>
                <circle cx="502" cy="323" r="1" fill="white"/>
                <circle cx="542" cy="323" r="1" fill="white"/>
                <circle cx="582" cy="323" r="1" fill="white"/>
                <circle cx="622" cy="323" r="1" fill="white"/>
                <circle cx="262" cy="363" r="1" fill="white"/>
                <circle cx="302" cy="363" r="1" fill="white"/>
                <circle cx="342" cy="363" r="1" fill="white"/>
                <circle cx="382" cy="363" r="1" fill="white"/>
                <circle cx="422" cy="363" r="1" fill="white"/>
                <circle cx="462" cy="363" r="1" fill="white"/>
                <circle cx="502" cy="363" r="1" fill="white"/>
                <circle cx="542" cy="363" r="1" fill="white"/>
                <circle cx="582" cy="363" r="1" fill="white"/>
                <circle cx="622" cy="363" r="1" fill="white"/>
                <circle cx="262" cy="403" r="1" fill="white"/>
                <circle cx="302" cy="403" r="1" fill="white"/>
                <circle cx="342" cy="403" r="1" fill="white"/>
                <circle cx="382" cy="403" r="1" fill="white"/>
                <circle cx="422" cy="403" r="1" fill="white"/>
                <circle cx="462" cy="403" r="1" fill="white"/>
                <circle cx="502" cy="403" r="1" fill="white"/>
                <circle cx="542" cy="403" r="1" fill="white"/>
                <circle cx="582" cy="403" r="1" fill="white"/>
                <circle cx="622" cy="403" r="1" fill="white"/>
                <circle cx="262" cy="443" r="1" fill="white"/>
                <circle cx="302" cy="443" r="1" fill="white"/>
                <circle cx="342" cy="443" r="1" fill="white"/>
                <circle cx="382" cy="443" r="1" fill="white"/>
                <circle cx="422" cy="443" r="1" fill="white"/>
                <circle cx="462" cy="443" r="1" fill="white"/>
                <circle cx="502" cy="443" r="1" fill="white"/>
                <circle cx="542" cy="443" r="1" fill="white"/>
                <circle cx="582" cy="443" r="1" fill="white"/>
                <circle cx="622" cy="443" r="1" fill="white"/>
                <circle cx="262" cy="483" r="1" fill="white"/>
                <circle cx="302" cy="483" r="1" fill="white"/>
                <circle cx="342" cy="483" r="1" fill="white"/>
                <circle cx="382" cy="483" r="1" fill="white"/>
                <circle cx="422" cy="483" r="1" fill="white"/>
                <circle cx="462" cy="483" r="1" fill="white"/>
                <circle cx="502" cy="483" r="1" fill="white"/>
                <circle cx="542" cy="483" r="1" fill="white"/>
                <circle cx="582" cy="483" r="1" fill="white"/>
                <circle cx="622" cy="483" r="1" fill="white"/>
                <circle cx="262" cy="523" r="1" fill="white"/>
                <circle cx="302" cy="523" r="1" fill="white"/>
                <circle cx="342" cy="523" r="1" fill="white"/>
                <circle cx="382" cy="523" r="1" fill="white"/>
                <circle cx="422" cy="523" r="1" fill="white"/>
                <circle cx="462" cy="523" r="1" fill="white"/>
                <circle cx="502" cy="523" r="1" fill="white"/>
                <circle cx="542" cy="523" r="1" fill="white"/>
                <circle cx="582" cy="523" r="1" fill="white"/>
                <circle cx="622" cy="523" r="1" fill="white"/>
                <defs>
                    <filter id="filter0_f_527_4169" x="-250" y="-330" width="1249.88" height="1160.82"
                            filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feGaussianBlur stdDeviation="160" result="effect1_foregroundBlur_527_4169"/>
                    </filter>
                    <linearGradient id="paint0_linear_527_4169" x1="17.4065" y1="-46.7612" x2="272.295" y2="-251.118"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#41F2D2"/>
                        <stop offset="1" stopColor="#38DBFF"/>
                    </linearGradient>
                </defs>
            </svg>
        </div>
    )
}

export default GreenGradient;
